import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './guards/auth.guard';

import { HomeComponent }       from './components/home/home.component';
import { LoginComponent }      from './components/login/login.component';
import { DashboardComponent }  from './components/dashboard/dashboard.component';
import { ProfileComponent }    from './components/profile/profile.component';
import { ItemsdbComponent }    from './components/itemsdb/itemsdb.component';
import { UserComponent }       from './components/user/user.component';
import { TourComponent }       from './components/tour/tour.component';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home',       component: HomeComponent},
  { path: 'login',      component: LoginComponent },
  { path: 'dashboard',  component: DashboardComponent,  canActivate:[AuthGuard] },
  { path: 'profile',    component: ProfileComponent,    canActivate:[AuthGuard] },
  { path: 'itemsdb',    component: ItemsdbComponent,    canActivate:[AuthGuard] },
  { path: 'user',       component: UserComponent,       canActivate:[AuthGuard] },
  { path: 'tour',       component: TourComponent,       canActivate:[AuthGuard] },
  { path: '**', redirectTo: 'home' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
