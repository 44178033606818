import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigGlobals } from '../globals/config';

@Injectable({
  providedIn: 'root'
})
export class AddressService {

  authToken: any;

  constructor( private httpClient : HttpClient,
               private configGlobals: ConfigGlobals)
{ }

  /*** Json WebToken laden für Protected Routes ****/
  loadToken() {
    const token = localStorage.getItem('id_token');
    this.authToken = token;
  }

  dbCountTableAddress(query) {
    this.loadToken(); // route is protected
    let headers = new HttpHeaders().set('Authorization', this.authToken); headers.append('Content-Type', 'application/json');
    let obs: any = this.httpClient.post(this.configGlobals.http_string + 'address_r/dbcounttableaddress', query, {headers: headers});
    return obs;
  } 

  dbFindPageAddress(query) {
    this.loadToken(); // route is protected
    let headers = new HttpHeaders().set('Authorization', this.authToken); headers.append('Content-Type', 'application/json');
    let obs: any = this.httpClient.post(this.configGlobals.http_string + 'address_r/dbfindpageaddress', query, {headers: headers})
    return obs;
  }

  dbFindAllAddress(query) {
    this.loadToken(); // route is protected
    let headers = new HttpHeaders().set('Authorization', this.authToken); headers.append('Content-Type', 'application/json');
    let obs: any = this.httpClient.post(this.configGlobals.http_string + 'address_r/dbfindalladdress', query, {headers: headers})
    return obs;
  }

  dbModifyAddress(query) {
    this.loadToken(); // route is protected
    let headers = new HttpHeaders().set('Authorization', this.authToken); headers.append('Content-Type', 'application/json');
    let obs: any = this.httpClient.post(this.configGlobals.http_string + 'address_r/dbmodifyaddress', query, {headers: headers})
    return obs;
  }

  dbMoveAddress(query) {
    this.loadToken(); // route is protected
    let headers = new HttpHeaders().set('Authorization', this.authToken); headers.append('Content-Type', 'application/json');
    let obs: any = this.httpClient.post(this.configGlobals.http_string + 'address_r/dbmoveaddress', query, {headers: headers})
    return obs;
  }

  dbInsertAddress(query) {
    this.loadToken(); // route is protected
    let headers = new HttpHeaders().set('Authorization', this.authToken); headers.append('Content-Type', 'application/json');
    let obs: any = this.httpClient.post(this.configGlobals.http_string + 'address_r/dbinsertaddress', query, {headers: headers})
    return obs;
  }

  dbDuplicateAddress(query) {
    this.loadToken(); // route is protected
    let headers = new HttpHeaders().set('Authorization', this.authToken); headers.append('Content-Type', 'application/json');
    let obs: any = this.httpClient.post(this.configGlobals.http_string + 'address_r/dbduplicateaddress', query, {headers: headers})
    return obs;
  }

  dbDeleteAddress(query) {
    this.loadToken(); // route is protected
    let headers = new HttpHeaders().set('Authorization', this.authToken); headers.append('Content-Type', 'application/json');
    let obs: any = this.httpClient.post(this.configGlobals.http_string + 'address_r/dbdeleteaddress', query, {headers: headers})
    return obs;
  }

}
