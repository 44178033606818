import { Component, OnInit, NgZone } from '@angular/core';
import { UserService } from '../../services/user.service';
import { FlashMessagesService } from 'angular2-flash-messages';
import { AuthService } from '../../services/auth.service'; // Zugriffs-Rechte
import { Router } from '@angular/router';
//import { State } from "@clr/angular";

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {
  // _id int(11) AI PK 

  username:     string = '';
  s_username:   string = '';    

  // Für Zurücksetzung User-Passwort und Benutzerverwaltung
  admin_pwd: string = '';
  user_pwd:  string = '';

  n_username  : string = '';
  n_password  : string = '';
  n_password_2: string = '';
  n_password_a: string = '';
  n_rights    : number = 0;
  n_role      : string = '';
  n_name      : string = '';
  n_email     : string = '';
  n_phone     : string = '';
  
  showModifyModal    = false;
  showInsertModal    = false;
  showPasswordModal  = false;
  showDeleteModal    = false;

  selected;  // beinhaltet den selektierten Datensatz im html; normalerweise *false*
  grid_Array         = [];   
  grid_Size          = 10;
  grid_currentPage   = 1;
  grid_totalCount    = 0;    
  grid_searchModus   = false; // false: ab Anfang / true = enthält contains/pattern
  grid_loading       = true;
  
  mobHeight: any;
  mobOffset = 280;
  mobDivide =  39;

  constructor( private userService: UserService,
               private flashMessage: FlashMessagesService,
               private authService: AuthService,
               private router: Router,
               private ngZone: NgZone
  ) {

    this.mobHeight = (window.innerHeight);
    this.grid_Size = Math.floor((this.mobHeight - this.mobOffset) / this.mobDivide);
    if (this.grid_Size < 1) {
      this.grid_Size = 1;
    }

    window.onresize = (e) =>
      {   ngZone.run(() => {
              this.mobHeight = window.innerHeight;
              this.grid_Size = Math.floor((this.mobHeight - this.mobOffset) / this.mobDivide);
              if (this.grid_Size < 1) {
                this.grid_Size = 1;
              }
              this.refresh();
          });
      };
  }

  ngOnInit() {
  }

  // *** COUNT & FIND Item_DB **************************************************
  // refresh(state: State) {  .. wenn Tastenstatus / Maus-Event eine Rolle spielt
  refresh() {      
    const query = {
      page    : 0,
      size    : 0,
      qstring : '',
    }
    var q = 'WHERE '; // Länge bleibt 7 wenn sonst alles leer
    var m = '';

    if (this.grid_searchModus)  m = '%';
    else                        m = '';
   
    this.s_username = this.s_username.trim();
    if (this.s_username  != '') {q = q + "username LIKE '"  + m + this.s_username +"%'"; }    
    
    if (q.length < 7) //q = '';  // alle Felder waren leer wenn Länge = 7
       q = "ORDER BY username ASC"  ;

    query.size    = this.grid_Size;
    query.page    = this.grid_currentPage;
    query.qstring = q;

    this.grid_loading = true;
    
    this.userService.dbCountTableUser(query).subscribe(data => {
      if (data.success) {
        //this.flashMessage.show('Items_DB counted', {cssClass: 'alert-success', timeout: 2000}); 
        this.grid_totalCount = data.count[0].anzahl;
        if (this.grid_totalCount > 0) {
          this.userService.dbFindPageUser(query).subscribe(data => {
            if (data.success) {
              //this.flashMessage.show('Items_DB found', {cssClass: 'alert-success', timeout: 2000}); 
              this.grid_Array = data.items; 
              this.selected = this.grid_Array[0];
              this.grid_loading = false;
              //console.log(this.grid_Array);
            } else {
              this.flashMessage.show('User_DB User not found, check connection', {cssClass: 'alert-danger', timeout: 2000}); 
            }
          });
        } else {
          delete this.grid_Array; 
          this.selected = [];
          this.grid_loading = false;
          this.flashMessage.show('User_DB Length = 0, check connection', {cssClass: 'alert-danger', timeout: 2000}); 
        }
      } else {
        delete this.grid_Array; 
        this.selected = [];
        this.grid_loading = false;
        this.flashMessage.show('User_DB not counted error, check connection', {cssClass: 'alert-danger', timeout: 2000}); 
      }
    });

  }
  
  // *** Modify EDIT ***************************
  selectedModifyModalOK() {
      const query = { qstring:   '',
                      admin_pwd: '' } 

      // Validate Rights
      if (!this.authService.checkOkRights(100)) {
        this.showModifyModal = false;
        this.admin_pwd = ''; 
        this.refresh();  // muss hier sein
        this.flashMessage.show('> Keine Rechte zur Modifikation <', {cssClass: 'alert-danger', timeout: 3000});
        return false;
      }

      query.qstring = "username='"+this.selected.username+"',rights="+this.selected.rights+",role='"+this.selected.role+
      "',name='"+this.selected.name+"',email='"+this.selected.email+"',phone='"+this.selected.phone+"' WHERE _id = "+this.selected._id;

      this.showModifyModal = false;

      if (this.admin_pwd != '') {
        query.admin_pwd = this.admin_pwd;
        this.userService.dbModifyUser(query).subscribe(data => {
          if (data.success) {
            //this.flashMessage.show('Item modified', {cssClass: 'alert-success', timeout: 1000}); 
            this.refresh();
          } else {
            this.flashMessage.show('Benutzer nicht geändert, Admin-Passwort prüfen, check connection', {cssClass: 'alert-danger', timeout: 3000}); 
            this.refresh();
          }
        });
      } else {
           this.refresh();
           this.flashMessage.show('Admin-Password benötigt', {cssClass: 'alert-danger', timeout: 3000}); 
      }  
      this.admin_pwd = ''; 

  }
  selectedModifyModalCANCEL() {
    this.admin_pwd = ''; 
    this.showModifyModal = false;
    this.refresh();  // muss hier sein
  }

  // *** INSERT NEW **********************
  selectedInsertModalOK() {
    const user = {  username: '', 
                    password: '',
                    rights:   0,
                    role:     '',
                    name:     '',
                    email:    '',
                    phone:    '',
                    admin_pwd:''
    }    
      
    // Validate Rights
    if (!this.authService.checkOkRights(100)) {
      this.n_password   = '';
      this.n_password_2 = '';
      this.n_password_a = '';
      this.showInsertModal = false;
      this.refresh();
      this.flashMessage.show('> Keine Rechte zur Modifikation <', {cssClass: 'alert-danger', timeout: 3000});
      return false;
    }
    
    this.showInsertModal = false;

    if ((this.n_username.length > 2 ) && (this.n_password.length > 2 ) && (this.n_password == this.n_password_2) && (this.n_password_a != '')) {

      user.username = this.n_username;
      user.password = this.n_password;
      user.rights   = this.n_rights;
      user.role     = this.n_role;
      user.name     = this.n_name;
      user.email    = this.n_email;
      user.phone    = this.n_phone;
      user.admin_pwd = this.n_password_a;

      this.userService.dbInsertUser(user).subscribe(data => {
        if (data.success) {
          this.flashMessage.show('Neuen Benutzer angelegt', {cssClass: 'alert-success', timeout: 2000}); 
          this.refresh();
        } else {
          this.flashMessage.show('Admin PW falsch oder Benutzer existiert schon, check connection', {cssClass: 'alert-danger', timeout: 3000}); 
          this.n_password   = '';
          this.n_password_2 = '';
          this.n_password_a = '';
        }
      });

    } else {
      this.flashMessage.show('> Benutzer zu kurz oder Passwörter nicht gleich, zu kurz oder kein Admin-Passwort <', {cssClass: 'alert-danger', timeout: 3000});
      this.n_password   = '';
      this.n_password_2 = '';
      this.n_password_a = '';
    }

  }
  selectedInsertModalCANCEL() {
    this.n_password   = '';
    this.n_password_2 = '';
    this.n_password_a = '';
    this.showInsertModal = false;
  }


  // *** DELETE ***************************
  selectedDeleteModalOK() {
    const query = { qstring:   '',
                    admin_pwd: '' } 

    if (!this.authService.checkOkRights(100)) {
      this.admin_pwd = '';
      this.showDeleteModal = false;
      //this.refresh();
      this.flashMessage.show('> Keine Rechte zur Modifikation <', {cssClass: 'alert-danger', timeout: 3000});
      return false;
    }    

    query.qstring= this.selected._id;
    
    this.showDeleteModal = false;

    if (this.admin_pwd != '') {
        query.admin_pwd = this.admin_pwd;
        this.userService.dbDeleteUser(query).subscribe(data => {
          if (data.success) {
            //this.flashMessage.show('User wurde gelöscht', {cssClass: 'alert-success', timeout: 2000}); 
            this.refresh();
          } else {
            this.flashMessage.show('Admin Passwort falsch', {cssClass: 'alert-danger', timeout: 3000}); 
          }
        });
    } else {
      this.refresh();
      this.flashMessage.show('Admin-Password benötigt', {cssClass: 'alert-danger', timeout: 3000}); 
    }  
    this.admin_pwd = ''; 
  }
  selectedDeleteModalCANCEL() {
    this.admin_pwd = ''; 
    this.showDeleteModal = false;
  }


  // *** Password ändern ***************************
  selectedPasswordModalOK() {
    const query = { username:  '',
                    new_pwd:   '',
                    admin_pwd: '' } 

    // Validate Rights
    if (!this.authService.checkOkRights(100)) {
      this.user_pwd  = '';
      this.admin_pwd = '';  
      this.showPasswordModal = false;
      this.refresh();  // muss hier sein
      this.flashMessage.show('> Keine Rechte zur Modifikation <', {cssClass: 'alert-danger', timeout: 3000});
      return false;
    }

    this.showPasswordModal = false;

    if ((this.user_pwd != '') && (this.user_pwd.length > 2 ) && (this.admin_pwd != '')) {

      query.username  = this.selected.username;
      query.new_pwd   = this.user_pwd;
      query.admin_pwd = this.admin_pwd;
      
      this.authService.dbResetPassword(query).subscribe(data => {
        if (data.success) {
          this.flashMessage.show('Passwort geändert', {cssClass: 'alert-success', timeout: 3000}); 
        } else {
          this.flashMessage.show('Passwort nicht geändert, Verbindung prüfen', {cssClass: 'alert-danger', timeout: 3000}); 
        }
      });
    } else {
       this.flashMessage.show('Passwort leer oder zu kurz oder nicht gleich', {cssClass: 'alert-danger', timeout: 4000}); 
    }

    this.user_pwd  = '';
    this.admin_pwd = '';  
  }
  selectedPasswordModalCANCEL() {
    this.user_pwd  = '';
    this.admin_pwd = '';  
    this.showPasswordModal = false;
  }

}
