import { Component, OnInit, NgZone } from '@angular/core';
import { TourService }    from '../../services/tour.service';
import { FlashMessagesService } from 'angular2-flash-messages';
import { Router } from '@angular/router';


@Component({
    selector: 'app-dashboard',
    templateUrl:'./dashboard.component.html',
    styleUrls: ['./dashboard.component.css']
})

export class DashboardComponent implements OnInit {


  today:      string = ''; // Datum '2018-11-15'

  // TOUR: Suchfelder
  s_date:     string = ''; // Datum '06.12.2018'   

  // GRID 1
  selected;  // beinhaltet den selektierten Datensatz im html; normalerweise *false*
  grid_Array         = [];   
  grid_Size          = 0;   // wird automatisch ermittelt
  grid_currentPage   = 1;
  grid_totalCount    = 0;    
  grid_searchModus   = false; // false: ab Anfang / true = enthält contains/pattern
  grid_loading       = true;
  grid_empty = true;

  // GRID 1 nur wenn dynamisch
  mobHeight: any;
  mobOffset = 280;
  mobDivide =  33;

  constructor( private tourService:    TourService,
               private flashMessage:   FlashMessagesService,
               private router: Router,
               private ngZone: NgZone
  ) {

    var d = new Date();
    
    var year  = d.getFullYear(); 
    var month = d.getMonth()+1;
    var day   = d.getDate();

    var s_year  = year.toString();
    var s_month = month.toString();
    var s_day   = day.toString();

    if (month < 10) s_month = '0'+s_month
    if (day   < 10) s_day   = '0'+s_day
    
    this.today  = s_year+'-'+s_month+'-'+s_day;
    this.s_date = this.today;

    this.mobHeight = (window.innerHeight);
    this.grid_Size = Math.floor((this.mobHeight - this.mobOffset) / this.mobDivide);
    if (this.grid_Size < 1) {
      this.grid_Size = 1;
    }
    window.onresize = (e) =>
      {   ngZone.run(() => {
            this.mobHeight = window.innerHeight;
            this.grid_Size = Math.floor((this.mobHeight - this.mobOffset) / this.mobDivide);
            if (this.grid_Size < 1) {
              this.grid_Size = 1;
            }
            this.refresh();
          });
      };  
  }

  ngOnInit() {
  }

    // *** COUNT & FIND Tour **************************************************
  // refresh(state: State) {  .. wenn Tastenstatus / Maus-Event eine Rolle spielt

  // GRID 1 ***************************************************************
  refresh() {      
    const query = {
      page    : 0,
      size    : 0,
      qstring : '',
    }
    var q = "WHERE date >= '"+this.s_date+"' ORDER BY date ASC";

    query.size    = this.grid_Size;
    query.page    = this.grid_currentPage;
    query.qstring = q;

    this.grid_loading = true;
    
    this.tourService.dbCountTableTour(query).subscribe(data => {
      if (data.success) {
        //this.flashMessage.show('Items_DB counted', {cssClass: 'alert-success', timeout: 2000}); 
        this.grid_totalCount = data.count[0].anzahl;
        if (this.grid_totalCount > 0) {
          this.tourService.dbFindPageTour(query).subscribe(data => {
            if (data.success) {
              //this.flashMessage.show('Items_DB found', {cssClass: 'alert-success', timeout: 2000}); 
              this.grid_Array = data.items; 
              this.selected = this.grid_Array[0];
              this.grid_empty = false;
              this.grid_loading = false;
            } else {
              this.flashMessage.show('Tour nicht gefunden, check connection', {cssClass: 'alert-danger', timeout: 2000}); 
            }
          });
        } else {
          delete this.grid_Array; 
          this.selected = [];
          this.grid_loading = false;
          this.grid_empty = true;
          this.flashMessage.show('Tour nicht gefunden, check connection', {cssClass: 'alert-danger', timeout: 2000}); 
        }
      } else {
        delete this.grid_Array; 
        this.selected = [];
        this.grid_loading = false;
        this.grid_empty = true;
        this.flashMessage.show('Tour nichtgefunden, check connection', {cssClass: 'alert-danger', timeout: 2000}); 
      }
    });
  }

}
