import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// import { tokenNotExpired } from '@auth0/angular-jwt';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ConfigGlobals } from '../globals/config';


@Injectable()
export class AuthService {
  
  authToken: any;
  user: any;

  constructor( private httpClient: HttpClient,
               private configGlobals: ConfigGlobals)
  {}

 
  registerUser(user) {

    // JWT Token mitschicken im Header
    this.loadToken();
    let headers = new HttpHeaders().set('Authorization', this.authToken); headers.append('Content-Type', 'application/json');
    
    // return true or false along with the message
    // *local*

    let obs: any = this.httpClient.post(this.configGlobals.http_string + 'user_r/register', user, {headers: headers});
    // *heroku Netcup *
    //return this.http.post('users/register', user, {headers: headers})
    return obs;       
  }

  // prüfen ob [admin] vorhanden ist 
  checkforAdmin(user) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    // return true or false along with the message, returns a Token and User-Info
    // *local*
    let obs: any = this.httpClient.post(this.configGlobals.http_string + 'user_r/checkforadmin', user, {headers: headers});
    // *heroku*
    //return this.http.post('users/authenticate', user, {headers: headers})
    return obs;
  }
  // kommt eigentlich nur bei leerer Datenbank vor
  registerAdmin(user) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    let obs: any = this.httpClient.post(this.configGlobals.http_string + 'user_r/registeradmin', user, {headers: headers});
    return obs;       
  }

  authenticateUser(user) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    // return true or false along with the message, returns a Token and User-Info
    // *local*
    let obs: any = this.httpClient.post(this.configGlobals.http_string + 'user_r/authenticate', user, {headers: headers});
    // *heroku*
    //return this.http.post('users/authenticate', user, {headers: headers})
    return obs;
  }

  dbModifyPassword(user) {  // durch User selbst

    // JWT Token mitschicken im Header
    this.loadToken();
    let headers = new HttpHeaders().set('Authorization', this.authToken); headers.append('Content-Type', 'application/json');

    // return true or false along with the message, returns a Token and User-Info
    // *local*
    let obs: any = this.httpClient.post(this.configGlobals.http_string + 'user_r/dbmodifypassword', user, {headers: headers});
    // *heroku*
    //return this.http.post('users/authenticate', user, {headers: headers})
    return obs;
  }

  dbResetPassword(user) {  // durch Admin ausgeführt

    // JWT Token mitschicken im Header
    this.loadToken();
    let headers = new HttpHeaders().set('Authorization', this.authToken); headers.append('Content-Type', 'application/json');

    // return true or false along with the message, returns a Token and User-Info
    // *local*
    let obs: any = this.httpClient.post(this.configGlobals.http_string + 'user_r/dbresetpassword', user, {headers: headers});
    // *heroku*
    //return this.http.post('users/authenticate', user, {headers: headers})
    return obs;
  }

  getProfile() {

    // JWT Token mitschicken im Header
    this.loadToken();
    let headers = new HttpHeaders().set('Authorization', this.authToken); headers.append('Content-Type', 'application/json');

    // *local*
    let obs: any = this.httpClient.get(this.configGlobals.http_string + 'user_r/profile', {headers: headers});
    // *heroku*
    //return this.http.get('users/profile', {headers: headers})
    return obs;
  }

  storeUserData(token, user) {
                       // id_token is the standard identifier for jwt-token
    localStorage.setItem('id_token', token);
                       // local storage can only store strings (no objects)
    localStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem('rights', user.rights);
    //localStorage.setItem('role', user.role);
    this.authToken = token;
    this.user = user;
  }

  loadToken() {
    const token = localStorage.getItem('id_token');
    this.authToken = token;
  }

  checkOkRights(rights) {
    const user_rights = parseInt(localStorage.getItem('rights')); // localStorge liefert immer string zurück

    if (user_rights >= rights)
      return(true);
    else
      return(false);    
  }

  loggedIn() {
    const helper = new JwtHelperService();
    const token: string = this.authToken;
    
    return token != null && !helper.isTokenExpired(token);
  }

  logout() {
    this.authToken = null;
    this.user = null; 
    localStorage.clear();
  }

}
