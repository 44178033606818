import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { HttpClientModule } from '@angular/common/http'; 

import { FlashMessagesModule } from 'angular2-flash-messages';
import { AppRoutingModule } from './app-routing.module';
import { ClarityModule } from "@clr/angular";

import { AppComponent }       from './app.component';
import { NavbarComponent }    from './components/navbar/navbar.component';

import { ValidateService } from './services/validate.service';
import { AuthService } from './services/auth.service';
import { ConfigGlobals } from './globals/config';

import { AuthGuard } from './guards/auth.guard';

import { HomeComponent } from       './components/home/home.component';
import { LoginComponent } from      './components/login/login.component';
import { DashboardComponent } from  './components/dashboard/dashboard.component';
import { ProfileComponent } from    './components/profile/profile.component';
import { ItemsdbComponent } from    './components/itemsdb/itemsdb.component';
import { TourComponent } from       './components/tour/tour.component';
import { UserComponent } from       './components/user/user.component';

/*
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
registerLocaleData(localeDe);
*/

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    LoginComponent,
    HomeComponent,
    DashboardComponent,
    ProfileComponent,
    ItemsdbComponent,
    TourComponent,
    UserComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    FlashMessagesModule.forRoot(),
    AppRoutingModule,
    ClarityModule
  ],
  providers: [
    ValidateService, 
    AuthService,
    AuthGuard,
    ConfigGlobals
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }
