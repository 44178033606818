import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigGlobals } from '../globals/config';

@Injectable({
  providedIn: 'root'
})
export class ItemsdbService {

  authToken: any;
  // items_db: any;

  constructor( private httpClient : HttpClient,
               private configGlobals: ConfigGlobals) 
  {}

  /*** Json WebToken laden für Protected Routes ****/
  loadToken() {
    const token = localStorage.getItem('id_token');
    this.authToken = token;
  }

  dbCountTableItems_db(query) {
    this.loadToken(); // route is protected
    let headers = new HttpHeaders().set('Authorization', this.authToken); headers.append('Content-Type', 'application/json');
    let obs: any = this.httpClient.post(this.configGlobals.http_string + 'items_db_r/dbcounttableitems_db', query, {headers: headers});
    return obs;
  } 

  dbFindPageItems_db(query) {
    this.loadToken(); // route is protected
    let headers = new HttpHeaders().set('Authorization', this.authToken); headers.append('Content-Type', 'application/json');
    let obs: any = this.httpClient.post(this.configGlobals.http_string + 'items_db_r/dbfindpageitems_db', query, {headers: headers})
    return obs;
  }

  dbFindAllItems_db(query) {
    this.loadToken(); // route is protected
    let headers = new HttpHeaders().set('Authorization', this.authToken); headers.append('Content-Type', 'application/json');
    let obs: any = this.httpClient.post(this.configGlobals.http_string + 'items_db_r/dbfindallitems_db', query, {headers: headers})
    return obs;
  }

  dbModifyItems_db(query) {
    this.loadToken(); // route is protected
    let headers = new HttpHeaders().set('Authorization', this.authToken); headers.append('Content-Type', 'application/json');
    let obs: any = this.httpClient.post(this.configGlobals.http_string + 'items_db_r/dbmodifyitems_db', query, {headers: headers})
    return obs;
  }

  dbInsertItems_db(query) {
    this.loadToken(); // route is protected
    let headers = new HttpHeaders().set('Authorization', this.authToken); headers.append('Content-Type', 'application/json');
    let obs: any = this.httpClient.post(this.configGlobals.http_string + 'items_db_r/dbinsertitems_db', query, {headers: headers})
    return obs;
  }

  dbDuplicateItems_db(query) {
    this.loadToken(); // route is protected
    let headers = new HttpHeaders().set('Authorization', this.authToken); headers.append('Content-Type', 'application/json');
    let obs: any = this.httpClient.post(this.configGlobals.http_string + 'items_db_r/dbduplicateitems_db', query, {headers: headers})
    return obs;
  }

  dbDeleteItems_db(query) {
    this.loadToken(); // route is protected
    let headers = new HttpHeaders().set('Authorization', this.authToken); headers.append('Content-Type', 'application/json');
    let obs: any = this.httpClient.post(this.configGlobals.http_string + 'items_db_r/dbdeleteitems_db', query, {headers: headers})
    return obs;
  }

}
