import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigGlobals } from '../globals/config';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  authToken: any;
  // user: any;

  constructor( private httpClient : HttpClient,
               private configGlobals: ConfigGlobals) 
  {}

  /*** Json WebToken laden für Protected Routes ****/
  loadToken() {
    const token = localStorage.getItem('id_token');
    this.authToken = token;
  }

  dbCountTableUser(query) {
    this.loadToken(); // route is protected
    let headers = new HttpHeaders().set('Authorization', this.authToken); headers.append('Content-Type', 'application/json');
    let obs: any = this.httpClient.post(this.configGlobals.http_string + 'user_r/dbcounttableuser', query, {headers: headers});
    return obs;
  } 

  dbFindPageUser(query) {
    this.loadToken(); // route is protected
    let headers = new HttpHeaders().set('Authorization', this.authToken); headers.append('Content-Type', 'application/json');
    let obs: any = this.httpClient.post(this.configGlobals.http_string + 'user_r/dbfindpageuser', query, {headers: headers})
    return obs;
  }

  dbFindAllUser(query) {
    this.loadToken(); // route is protected
    let headers = new HttpHeaders().set('Authorization', this.authToken); headers.append('Content-Type', 'application/json');
    let obs: any = this.httpClient.post(this.configGlobals.http_string + 'user_r/dbfindalluser', query, {headers: headers})
    return obs;
  }

  dbModifyUser(query) {
    this.loadToken(); // route is protected
    let headers = new HttpHeaders().set('Authorization', this.authToken); headers.append('Content-Type', 'application/json');
    let obs: any = this.httpClient.post(this.configGlobals.http_string + 'user_r/dbmodifyuser', query, {headers: headers})
    return obs;
  }

  dbInsertUser(user) {
    this.loadToken(); // route is protected
    let headers = new HttpHeaders().set('Authorization', this.authToken); headers.append('Content-Type', 'application/json');
    let obs: any = this.httpClient.post(this.configGlobals.http_string + 'user_r/dbinsertuser', user, {headers: headers})
    return obs;
  }

  dbDeleteUser(query) {
    this.loadToken(); // route is protected
    let headers = new HttpHeaders().set('Authorization', this.authToken); headers.append('Content-Type', 'application/json');
    let obs: any = this.httpClient.post(this.configGlobals.http_string + 'user_r/dbdeleteuser', query, {headers: headers})
    return obs;
  }

}
