import { Component, OnInit, NgZone } from '@angular/core';
import { TourService }    from '../../services/tour.service';
import { AddressService } from '../../services/address.service';
import { ItemsService }   from '../../services/items.service';

import { AuthService }    from '../../services/auth.service';    // Zugriffs-Rechte
import { ItemsdbService } from '../../services/itemsdb.service'; // Items DB (Sperrmüllarten)

import { FlashMessagesService } from 'angular2-flash-messages';
import { Router } from '@angular/router';
//import { State } from "@clr/angular";


@Component({
  selector: 'app-tour',
  templateUrl: './tour.component.html',
  styleUrls: ['./tour.component.css']
})
export class TourComponent implements OnInit {

  cl = false;   // Console.Log true oder false

  today:      string = ''; // Datum '2018-11-15'

  // TOUR: Suchfelder
  s_date:     string = ''; // Datum '06.12.2018'   
  s_tour_id:  string = '';
  s_username: string = '';
  s_user_id:  string = '';

  // TOUR: Felder nötig für neuen Datensatz 
  date:       string = ''; // Datum '2018-11-15'
  user_id:    string = '';
  username:   string = '';
  comment:    string = '';
  info:       string = '';
  started:    string = ''; // Datum '06.12.2018'
  ended:      string = ''; // Datum '06.12.2018'
  closed:     string = ''; // Datum '06.12.2018'
  

  // Array für Fahrerauswahl
  driver_Array : { _id:      number; 
                   username: string;
                   name:     string } [] = [];
  
  // Array für Sperrmuellauswahl
  sperrmuell_Array : { _id:      number; 
                       group_id: number;
                       item:     string } [] = [];                   
  
  // GRID 1
  selected;  // beinhaltet den selektierten Datensatz im html; normalerweise *false*
  grid_Array         = [];   
  grid_Size          = 5;
  grid_currentPage   = 1;
  grid_totalCount    = 0;    
  grid_searchModus   = false; // false: ab Anfang / true = enthält contains/pattern
  grid_loading       = true;
  grid_empty = true;

  showInsertModal    = false;
  showModifyModal    = false;
  showDeleteModal    = false;
 
  // GRID 1 nur wenn dynamisch
  mobHeight: any;
  mobOffset = 280;
  mobDivide =  39;

  

  // GRID 2
  selected2: any;  // beinhaltet den selektierten Datensatz im html; normalerweise *false*
  grid_Array2         = [];   
  grid_Size2          = 100; // max Size, nur eine Seite, da verschachteltes Paging noch nicht in Angular implementiert ist
  grid_currentPage2   = 1;
  grid_totalCount2    = 0;    
  //grid_searchModus2   = false; // false: ab Anfang / true = enthält contains/pattern
  grid_loading2       = true;

  grid_id2 = 0;
  grid2_empty = true;
  showInsertModal2    = false;
  showModifyModal2    = false;
  showMoveModal2      = false;
  showDeleteModal2    = false;
  g2_sort     = '1';
  g2_name     = '';
  g2_address  = '';
  g2_plz      = '';
  g2_phone    = '';
  g2_comment  = '';
  g2_info     = '';
  g2_closed   = '';
  g2_move_id  = 0;
  
  // GRID 3
  selected3;  // beinhaltet den selektierten Datensatz im html; normalerweise *false*
  grid_Array3         = [];   
  grid_Size3          = 100;  // max Size, nur eine Seite, da verschachteltes Paging noch nicht in Angular implementiert ist
  grid_currentPage3   = 1;
  grid_totalCount3    = 0;    
  grid_searchModus3   = false; // false: ab Anfang / true = enthält contains/pattern
  grid_loading3       = true;

  grid_id3 = 0;
  grid3_empty = true;
  showInsertModal3    = false;
  showModifyModal3    = false;
  showDeleteModal3    = false;
  g3_dropdown = 0;
  g3_item     = '';
  g3_group_id = 0;
  g3_amount   = 1;
  g3_total    = 0;
  g3_comment  = '';
  g3_info     = '';
  g3_closed   = '';


  
  constructor( private tourService:    TourService,
               private addressService: AddressService,
               private itemsService:   ItemsService,
               private flashMessage:   FlashMessagesService,
               private authService:    AuthService,
               private itemsdbService: ItemsdbService,
               private router: Router,
               private ngZone: NgZone
  ) {

    var d = new Date();
    
    var year  = d.getFullYear(); 
    var month = d.getMonth()+1;
    var day   = d.getDate();

    var s_year  = year.toString();
    var s_month = month.toString();
    var s_day   = day.toString();

    if (month < 10) s_month = '0'+s_month
    if (day   < 10) s_day   = '0'+s_day
    
    this.today  = s_year+'-'+s_month+'-'+s_day;
    this.date   = this.today;
    this.s_date = this.today;

    // alle Fahrer zur späteren Auswahl einmal Laden
    const query = { qstring : 'Fahrer' }
    this.tourService.dbFindRole(query).subscribe(data => {
      if (data.success) {
        //this.flashMessage.show('User_DB found', {cssClass: 'alert-success', timeout: 2000}); 
        this.driver_Array = data.items; 
        this.driver_Array.unshift({_id: 0, username: '---', name: '---'}); 
        //console.log(this.driver_Array);
      } else {
        this.flashMessage.show('User_DB nicht gefunden, check connection', {cssClass: 'alert-danger', timeout: 2000}); 
      }
    });

    // alle Sperrmuellarten zur späteren Auswahl einmal Laden
    const query2 = { qstring : ' ORDER BY item ASC' } // alles laden, nur sortiert (ORDER BY group_id, item ASC)
    this.itemsdbService.dbFindAllItems_db(query2).subscribe(data => {
      if (data.success) {
        //this.flashMessage.show('User_DB found', {cssClass: 'alert-success', timeout: 2000}); 
        this.sperrmuell_Array = data.items; 
        //this.sperrmuell_Array.unshift({_id: 0, group_id: 0, item: ''}); 
        //console.log('Sperrmüll',this.sperrmuell_Array);

      } else {
        this.flashMessage.show('Sperrmüll_DB nicht gefunden, check connection', {cssClass: 'alert-danger', timeout: 2000}); 
      }
    });
    
    /*
    this.mobHeight = (window.innerHeight);
    this.grid_Size = Math.floor((this.mobHeight - this.mobOffset) / this.mobDivide);
    if (this.grid_Size < 1) {
      this.grid_Size = 1;
    }
    window.onresize = (e) =>
      {   ngZone.run(() => {
            this.mobHeight = window.innerHeight;
            this.grid_Size = Math.floor((this.mobHeight - this.mobOffset) / this.mobDivide);
            if (this.grid_Size < 1) {
              this.grid_Size = 1;
            }
            this.refresh();
          });
      };
    */  
  }

  ngOnInit() {
  }

  // *** COUNT & FIND Tour **************************************************
  // refresh(state: State) {  .. wenn Tastenstatus / Maus-Event eine Rolle spielt

  // GRID 1 ***************************************************************
  refresh() {      
    const query = {
      page    : 0,
      size    : 0,
      qstring : '',
    }
    var q = '';

    let t_tour_id = parseInt(this.s_tour_id);
    if (isNaN(t_tour_id)) { t_tour_id = 0; }
    if (t_tour_id > 0) {
      q = q + "WHERE _id = "+t_tour_id;
    }
    else {
      let t_user_id = parseInt(this.s_user_id);
      if (isNaN(t_user_id)) { t_user_id = 0; }
      if (t_user_id > 0) {
        q = q + "WHERE user_id = "+t_user_id;
      }   
      else {
        this.s_username = this.s_username.trim();
        if (this.s_username.length > 0) {
          q = q + "WHERE username = '"+this.s_username+"'";
        } else {
          this.s_date = this.s_date.trim();
          if (this.grid_searchModus) 
            q = q + "WHERE date <= '"+this.s_date+"'";
        }    
      }
    }  
    
    q = q + " ORDER BY date DESC"

    query.size    = this.grid_Size;
    query.page    = this.grid_currentPage;
    query.qstring = q;

    //console.log(query.qstring);

    this.grid_loading = true;
    
    this.tourService.dbCountTableTour(query).subscribe(data => {
      if (data.success) {
        //this.flashMessage.show('Items_DB counted', {cssClass: 'alert-success', timeout: 2000}); 
        this.grid_totalCount = data.count[0].anzahl;
        if (this.grid_totalCount > 0) {
          this.tourService.dbFindPageTour(query).subscribe(data => {
            if (data.success) {
              //this.flashMessage.show('Items_DB found', {cssClass: 'alert-success', timeout: 2000}); 
              this.grid_Array = data.items; 
              this.selected = this.grid_Array[0];
              this.grid_empty = false;
              this.grid_loading = false;
              
              if (this.cl) console.log('G1:',this.grid_Array);

              this.grid2();

            } else {
              this.flashMessage.show('Tour nicht gefunden, check connection', {cssClass: 'alert-danger', timeout: 2000}); 
            }
          });
        } else {
          delete this.grid_Array; 
          this.selected = [];
          this.grid_loading = false;
          this.grid_empty = true;

          delete this.grid_Array2;             
          this.selected2 = [];
          this.grid2_empty = true;
          this.grid_id2 = 0; // sowas von wichtig

          delete this.grid_Array3;             
          this.selected3 = [];
          this.grid3_empty = true;
          this.grid_id3 = 0; // sowas von wichtig

          this.flashMessage.show('Tour nicht gefunden, check connection', {cssClass: 'alert-danger', timeout: 2000}); 
        }
      } else {
        delete this.grid_Array; 
        this.selected = [];
        this.grid_loading = false;
        this.grid_empty = true;

        delete this.grid_Array2;             
        this.selected2 = [];
        this.grid2_empty = true;
        this.grid_id2 = 0; // sowas von wichtig

        delete this.grid_Array3;             
        this.selected3 = [];
        this.grid3_empty = true;
        this.grid_id3 = 0; // sowas von wichtig

        this.flashMessage.show('Tour nichtgefunden, check connection', {cssClass: 'alert-danger', timeout: 2000}); 
      }
    });
  }

  // GRID 2 ***************************************************************
  grid2() {
    const query = {
      page    : 0,
      size    : 0,
      qstring : '',
    }

    if ((this.selected._id != undefined) && (this.selected._id != this.grid_id2)) {
      this.grid_id2 = this.selected._id;

      var q = 'WHERE tour_id = '+this.grid_id2+" ORDER BY sort ASC"  ;

      query.size    = this.grid_Size2;
      query.page    = this.grid_currentPage2;
      query.qstring = q;

      this.grid_loading2 = true;
      
      this.addressService.dbCountTableAddress(query).subscribe(data => {
        if (data.success) {
          //this.flashMessage.show('Items_DB counted', {cssClass: 'alert-success', timeout: 2000}); 
          this.grid_totalCount2 = data.count[0].anzahl;
          if (this.grid_totalCount2 > 0) {
            this.addressService.dbFindPageAddress(query).subscribe(data => {
              if (data.success) {
                //this.flashMessage.show('Items_DB found', {cssClass: 'alert-success', timeout: 2000}); 
                this.grid_Array2 = data.items; 
                this.selected2 = this.grid_Array2[0];
                this.grid2_empty = false;
                this.grid_loading2 = false;

                if (this.cl) console.log('G2:',this.grid_Array2);
                
                this.grid3();

              } else {
                this.flashMessage.show('Addresse nicht gefunden, Verbindung überprüfen', {cssClass: 'alert-danger', timeout: 2000}); 
              }
            });
          } else {
              delete this.grid_Array2;             
              this.selected2 = [];
              this.grid2_empty = true;

              delete this.grid_Array3;             
              this.selected3 = [];
              this.grid3_empty = true;
              this.grid_id3 = 0; // sowas von wichtig
              
            // this.flashMessage.show('Address not counted or found, check connection', {cssClass: 'alert-danger', timeout: 2000}); 
          }
        } else {
             delete this.grid_Array2;           
             this.selected2 = [];
             this.grid2_empty = true;

             delete this.grid_Array3;             
             this.selected3 = [];
             this.grid3_empty = true;
             this.grid_id3 = 0; // sowas von wichtig

             this.flashMessage.show('Addresse nicht gefunden, Verbindung überprüfen', {cssClass: 'alert-danger', timeout: 2000}); 
        }
      });

    };  

  }

  // GRID 3 ***************************************************************

  grid3() {      
    const query = {
      page    : 0,
      size    : 0,
      qstring : '',
    }

    if ((this.selected2._id != undefined) && (this.selected2._id != this.grid_id3)) {
      this.grid_id3 = this.selected2._id;

      var q = 'WHERE address_id = '+this.grid_id3+" ORDER BY item ASC";

      query.size    = this.grid_Size2;
      query.page    = this.grid_currentPage2;
      query.qstring = q;

      this.grid_loading2 = true;
      
      this.itemsService.dbCountTableItems(query).subscribe(data => {
        if (data.success) {
          //this.flashMessage.show('Items_DB counted', {cssClass: 'alert-success', timeout: 2000}); 
          this.grid_totalCount3 = data.count[0].anzahl;
          if (this.grid_totalCount3 > 0) {
            this.itemsService.dbFindPageItems(query).subscribe(data => {
              if (data.success) {
                //this.flashMessage.show('Items_DB found', {cssClass: 'alert-success', timeout: 2000}); 
                this.grid_Array3 = data.items; 
                this.selected3 = this.grid_Array3[0];
                this.grid3_empty = false;
                this.grid_loading3 = false;
                
                if (this.cl) console.log('G3:',this.grid_Array3);

              } else {
                this.flashMessage.show('Items not found, check connection', {cssClass: 'alert-danger', timeout: 2000}); 
              }
            });
          } else {
            delete this.grid_Array3; 
            this.selected3 = [];
            this.grid3_empty = true;
            // this.flashMessage.show('Address not counted or found, check connection', {cssClass: 'alert-danger', timeout: 2000}); 
          }
        } else {
          delete this.grid_Array3; 
          this.selected3 = [];
          this.grid3_empty = true;
          this.flashMessage.show('Items not counted or found, check connection', {cssClass: 'alert-danger', timeout: 2000}); 
        }
      });

    };  

  }

  // *** Modify EDIT ***************************
  selectedModifyModalOK() {
      const query = { qstring : '' } 

      // Validate Rights
      if (!this.authService.checkOkRights(50)) {
        this.showModifyModal = false;
        this.refresh();  // muss hier sein
        this.flashMessage.show('> Keine Rechte zur Modifikation <', {cssClass: 'alert-danger', timeout: 3000});
        return false;
      }

      // User-ID ermitteln und zuweisen
      let x_user_id = 0;
      for (let i=0; i < this.driver_Array.length; i++) {
        if (this.selected.username == this.driver_Array[i].username)
          x_user_id = this.driver_Array[i]._id;
      }  

      query.qstring = "date='"+this.selected.date+"',user_id="+x_user_id+",username='"+this.selected.username+"',comment='"+this.selected.comment+"',info='"+this.selected.info+
      "',started='"+this.selected.started+"', ended='"+this.selected.ended+"', closed='"+this.selected.closed+"' WHERE _id = "+this.selected._id;

      this.showModifyModal = false;

      this.tourService.dbModifyTour(query).subscribe(data => {
        if (data.success) {
          //this.flashMessage.show('Tour modified', {cssClass: 'alert-success', timeout: 1000}); 
          this.refresh(); 
        } else {
          this.flashMessage.show('Tour nicht geändert, Verbindung prüfen', {cssClass: 'alert-danger', timeout: 3000}); 
        }
      });
      
  }
  selectedModifyModalCANCEL() {
    this.showModifyModal = false;
  }

  // *** INSERT NEW **********************
  selectedInsertModalOK() {
    const query = { qstring : '' } 

    // Validate Rights
    if (!this.authService.checkOkRights(50)) {
      this.showInsertModal = false;
      //this.refresh();
      this.flashMessage.show('> Keine Rechte zur Modifikation <', {cssClass: 'alert-danger', timeout: 3000});
      return false;
    }

    // User-ID ermitteln und zuweisen
    let x_user_id = 0;
    for (let i=0; i < this.driver_Array.length; i++) {
      if (this.username == this.driver_Array[i].username)
        x_user_id = this.driver_Array[i]._id;
    }  

    query.qstring = "date='"+this.date+"',user_id="+x_user_id+",username='"+this.username+"',comment='"+this.comment+
    "',info='"+this.info+"',started='', ended='', closed=''";

    this.showInsertModal = false;

    this.tourService.dbInsertTour(query).subscribe(data => {
      if (data.success) {
        //this.flashMessage.show('Tour neu angelegt', {cssClass: 'alert-success', timeout: 1000}); 
        this.refresh();
      } else {
        this.flashMessage.show('Tour nicht neu angelegt, Verbindung prüfen', {cssClass: 'alert-danger', timeout: 3000}); 
      }
    });

    this.date   = this.today; 
    this.username  = '';    
    this.comment   = '';    
    this.info      = '';    

  }
  selectedInsertModalCANCEL() {
    this.showInsertModal = false;

    this.date      = this.today;
    this.username  = '';    
    this.comment   = '';    
    this.info      = '';    

  }

  // *** DELETE ***************************
  selectedDeleteModalOK() {

    if (this.grid2_empty) { // besser nochmal prüfen, dass untergeordnete Tabelle leer ist    

        const query = { qstring : '' } 

        if (!this.authService.checkOkRights(50)) {
          this.showDeleteModal = false;
          //this.refresh();
          this.flashMessage.show('> Keine Rechte zur Modifikation <', {cssClass: 'alert-danger', timeout: 3000});
          return false;
        }    

        query.qstring= this.selected._id;
        
        this.showDeleteModal = false;

        this.tourService.dbDeleteTour(query).subscribe(data => {
          if (data.success) {
            //this.flashMessage.show('Tour deleted', {cssClass: 'alert-success', timeout: 1000}); 
            this.refresh();
          } else {
            this.flashMessage.show('Tour not deleted, check connection', {cssClass: 'alert-danger', timeout: 3000}); 
          }
        });

        //  this.selected = false;
        //  this.refresh();
      }
      else {
        this.showDeleteModal = false;
      }
  }    
  selectedDeleteModalCANCEL() {
    this.showDeleteModal = false;
  }


  //********** GRID 2 Adresse **************************************/

  // *** Modify EDIT ***************************
  selectedModifyModalOK2() {
    const query = { qstring : '' } 

    // Validate Rights
    if (!this.authService.checkOkRights(50)) {
      this.showModifyModal2 = false;
      this.grid_id2 = 0; // muss hier sein
      this.grid2();      // muss hier sein
      this.flashMessage.show('> Keine Rechte zur Modifikation <', {cssClass: 'alert-danger', timeout: 3000});
      return false;
    }
    
    let x_sort = parseInt(this.selected2.sort);
    if (isNaN(x_sort)) { x_sort = 1; }

    query.qstring = "sort="+x_sort+",plz='"+this.selected2.plz+"',address='"+this.selected2.address+"',name='"+
    this.selected2.name+"',phone='"+this.selected2.phone+"',comment='"+this.selected2.comment+"',info='"+
    this.selected2.info+"',closed='"+this.selected2.closed+"' WHERE _id = "+this.selected2._id;
                
    this.showModifyModal2 = false;
    
    this.addressService.dbModifyAddress(query).subscribe(data => {
      if (data.success) {
        //this.flashMessage.show('Tour modified', {cssClass: 'alert-success', timeout: 1000}); 
        this.grid_id2 = 0; // Neuladen erzwingen, damit die Sortierung angezeigt wird falls geändert
        this.grid2();
      } else {
        this.flashMessage.show('Adresse nicht geändert, Verbindung prüfen', {cssClass: 'alert-danger', timeout: 3000}); 
      }
    });
  }
  selectedModifyModalCANCEL2() {
    this.showModifyModal2 = false;
  }

// *** Move verschieben ***************************  
  selectedMoveModalOK2() {
    const query = { move_tour_id : 0,
                    qstring : '' } 

    // Validate Rights
    if (!this.authService.checkOkRights(50)) {
      this.showModifyModal2 = false;
      //this.refresh();  // muss hier sein
      this.flashMessage.show('> Keine Rechte zur Modifikation <', {cssClass: 'alert-danger', timeout: 3000});
      return false;
    }

    this.showMoveModal2 = false;

    if ((this.g2_move_id > 0) && (this.selected2.tour_id != this.g2_move_id)) {

      query.move_tour_id = this.g2_move_id;
      query.qstring = "tour_id="+this.g2_move_id+" WHERE _id = "+this.selected2._id;
  
      console.log('GO:',query);

      this.addressService.dbMoveAddress(query).subscribe(data => {
        if (data.success) {
          //this.flashMessage.show('Adresse wurde verschoben', {cssClass: 'alert-success', timeout: 1000}); 
          this.grid_id2 = 0; // Neuladen erzwingen, damit die Sortierung angezeigt wird falls geändert
          this.grid2();
        } else {
          this.flashMessage.show('Adresse wurde NICHT verschoben, Tour-ID prüfen, Verbindung prüfen', {cssClass: 'alert-danger', timeout: 3000}); 
        }
      });
 
    }
    this.g2_move_id = 0;
  }
  selectedMoveModalCANCEL2() {
    this.showMoveModal2 = false;
    this.g2_move_id = 0;
  }

  // *** INSERT NEW **********************
  selectedInsertModalOK2() {
    const query = { qstring : '' } 

    // Validate Rights
    if (!this.authService.checkOkRights(50)) {
      this.showInsertModal2 = false;
      //this.refresh();
      this.flashMessage.show('> Keine Rechte zur Modifikation <', {cssClass: 'alert-danger', timeout: 3000});
      return false;
    }

    let x_g2_sort = parseInt(this.g2_sort);
    if (isNaN(x_g2_sort)) { x_g2_sort = 1; }

    query.qstring = "tour_id="+this.selected._id+",sort="+x_g2_sort+",plz='"+this.g2_plz+"',address='"+this.g2_address+
    "',name='"+this.g2_name+"',phone='"+this.g2_phone+"',comment='"+this.g2_comment+"',info='"+this.g2_info+"',closed=''";

    this.showInsertModal2 = false;

    // console.log(query.qstring);
    this.addressService.dbInsertAddress(query).subscribe(data => {
      if (data.success) {
        //this.flashMessage.show('Tour inserted', {cssClass: 'alert-success', timeout: 1000}); 
        this.grid_id2 = 0; // grid neu laden
        this.grid2();
      } else {
        this.flashMessage.show('Adresse nicht neu abgelegt, Verbindung prüfen', {cssClass: 'alert-danger', timeout: 3000}); 
      }
    });

    this.g2_sort     = '1';
    this.g2_name     = '';
    this.g2_address  = '';
    this.g2_plz      = '';
    this.g2_phone    = '';
    this.g2_comment  = '';
    this.g2_info     = '';
    this.g2_closed   = '';
    
  }
  selectedInsertModalCANCEL2() {
    this.showInsertModal2 = false;
    this.g2_sort     = '1';
    this.g2_name     = '';
    this.g2_address  = '';
    this.g2_plz      = '';
    this.g2_phone    = '';
    this.g2_comment  = '';
    this.g2_info     = '';
    this.g2_closed   = '';
  }

  // *** DELETE ***************************
  selectedDeleteModalOK2() {

    if (this.grid3_empty) { // besser nochmal prüfen, dass untergeordnete Tabelle leer ist

        const query = { qstring : '' } 

        if (!this.authService.checkOkRights(50)) {
          this.showDeleteModal2 = false;
          //this.refresh();
          this.flashMessage.show('> Keine Rechte zur Modifikation <', {cssClass: 'alert-danger', timeout: 3000});
          return false;
        }    

        query.qstring= this.selected2._id;
        
        this.showDeleteModal2 = false;

        this.addressService.dbDeleteAddress(query).subscribe(data => {
          if (data.success) {
            //this.flashMessage.show('Adresse gelöscht', {cssClass: 'alert-success', timeout: 1000}); 
            this.grid_id2 = 0; // grid neu laden
            this.grid2();
          } else {
            this.flashMessage.show('Adresse nicht gelöscht, Verbindung prüfen', {cssClass: 'alert-danger', timeout: 3000}); 
          }
        });
    } 
    else {
        this.showDeleteModal2 = false;
    }  
  }
  selectedDeleteModalCANCEL2() {
    this.showDeleteModal2 = false;
  }

  //********** GRID 3 Sperrmüll-Items **************************************/

  // *** Modify EDIT ***************************
  selectedModifyModalOK3() {
    const query = { qstring : '' } 

    // Validate Rights
    if (!this.authService.checkOkRights(50)) {
      this.showModifyModal3 = false;
      this.grid_id3 = 0; // muss hier sein
      this.grid3();      // muss hier sein
      this.flashMessage.show('> Keine Rechte zur Modifikation <', {cssClass: 'alert-danger', timeout: 3000});
      return false;
    }

    // Item_Group-ID (Sperrmüllart) 0 = freier Text, ansonsten Übernahme aus ITEM_DB (Array)
    let x_group_id = 0;
    for (let i=0; i < this.sperrmuell_Array.length; i++) {
      if (this.selected3.item == this.sperrmuell_Array[i].item)
        x_group_id = this.sperrmuell_Array[i].group_id;
    }  

    this.showModifyModal3 = false;

    query.qstring = "group_id="+this.selected3.group_id+",item='"+this.selected3.item+"',amount="+this.selected3.amount+",total="+this.selected3.total+
    ",comment='"+this.selected3.comment+"',info='"+this.selected3.info+"',closed='"+this.selected3.closed+"' WHERE _id = "+this.selected3._id;

    this.itemsService.dbModifyItems(query).subscribe(data => {
      if (data.success) {
        //this.flashMessage.show('Tour modified', {cssClass: 'alert-success', timeout: 1000}); 
        this.grid_id3 = 0; // grid neu laden falls Sortierung geändert
        this.grid3();
      } else {
        this.flashMessage.show('Sperrmüll nicht geändert, Verbindung prüfen', {cssClass: 'alert-danger', timeout: 3000}); 
      }
    });

  }
  selectedModifyModalCANCEL3() {
    this.showModifyModal3 = false;
    //this.refresh();  // muss hier sein
  }

  // *** INSERT NEW **********************
  selectedInsertModalOK3() {
    const query = { qstring : '' } 

    // Validate Rights
    if (!this.authService.checkOkRights(50)) {
      this.showInsertModal3 = false;
      //this.refresh();
      this.flashMessage.show('> Keine Rechte zur Modifikation <', {cssClass: 'alert-danger', timeout: 3000});
      return false;
    }

    // Item_Group-ID (Sperrmüllart) 0 = freier Text, ansonsten Übernahme aus ITEM_DB (Array)
    let x_group_id = 0;
    for (let i=0; i < this.sperrmuell_Array.length; i++) {
      if (this.g3_item == this.sperrmuell_Array[i].item)
        x_group_id = this.sperrmuell_Array[i].group_id;
    }  

    this.showInsertModal3 = false;

    query.qstring = "address_id="+this.selected2._id+",group_id="+x_group_id+",item='"+this.g3_item+
    "',amount="+this.g3_amount+",total=0, comment='"+this.g3_comment+"',info='',closed=''";
  
    this.itemsService.dbInsertItems(query).subscribe(data => {
      if (data.success) {
        //this.flashMessage.show('Tour inserted', {cssClass: 'alert-success', timeout: 1000}); 
        this.grid_id3 = 0; // grid neu laden
        this.grid3();
      } else {
        this.flashMessage.show('Tour not inserted, check connection', {cssClass: 'alert-danger', timeout: 3000}); 
      }
    });

    this.g3_group_id = 0;
    this.g3_item = '';
    this.g3_amount = 1;
    this.g3_comment = '';
    
  }
  selectedInsertModalCANCEL3() {
    this.showInsertModal3 = false;
    this.g3_group_id = 0;
    this.g3_item = '';
    this.g3_amount = 1;
    this.g3_comment = '';

    //grid3();
  }

  // *** DELETE ***************************
  selectedDeleteModalOK3() {
    const query = { qstring : '' } 

    if (!this.authService.checkOkRights(50)) {
      this.showDeleteModal3 = false;
      //this.refresh();
      this.flashMessage.show('> Keine Rechte zur Modifikation <', {cssClass: 'alert-danger', timeout: 3000});
      return false;
    }    

    query.qstring = this.selected3._id;
    
    this.showDeleteModal3 = false;

    this.itemsService.dbDeleteItems(query).subscribe(data => {
      if (data.success) {
        //this.flashMessage.show('Sperrmüll gelösch', {cssClass: 'alert-success', timeout: 1000}); 
        this.grid_id3 = 0; // grid neu laden
        this.grid3();
      } else {
        this.flashMessage.show('Sperrmüll nicht gelöscht, Verbindung prüfen', {cssClass: 'alert-danger', timeout: 3000}); 
      }
    });

  }
  selectedDeleteModalCANCEL3() {
    this.showDeleteModal3 = false;
  }


}
