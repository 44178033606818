import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigGlobals } from '../globals/config';

@Injectable({
  providedIn: 'root'
})
export class TourService {

  authToken: any;

  constructor( private httpClient : HttpClient,
               private configGlobals: ConfigGlobals)
  { }

  /*** Json WebToken laden für Protected Routes ****/
  loadToken() {
    const token = localStorage.getItem('id_token');
    this.authToken = token;
  }

  dbFindRole(query) {
    this.loadToken(); // route is protected
    let headers = new HttpHeaders().set('Authorization', this.authToken); headers.append('Content-Type', 'application/json');
    let obs: any = this.httpClient.post(this.configGlobals.http_string + 'user_r/dbfindrole', query, {headers: headers});
    return obs;
  } 

  dbFindTrash(query) {
    this.loadToken(); // route is protected
    let headers = new HttpHeaders().set('Authorization', this.authToken); headers.append('Content-Type', 'application/json');
    let obs: any = this.httpClient.post(this.configGlobals.http_string + 'items_db_r/dbfindtrash', query, {headers: headers});
    return obs;
  } 

  dbCountTableTour(query) {
    this.loadToken(); // route is protected
    let headers = new HttpHeaders().set('Authorization', this.authToken); headers.append('Content-Type', 'application/json');
    let obs: any = this.httpClient.post(this.configGlobals.http_string + 'tour_r/dbcounttabletour', query, {headers: headers});
    return obs;
  } 

  dbFindPageTour(query) {
    this.loadToken(); // route is protected
    let headers = new HttpHeaders().set('Authorization', this.authToken); headers.append('Content-Type', 'application/json');
    let obs: any = this.httpClient.post(this.configGlobals.http_string + 'tour_r/dbfindpagetour', query, {headers: headers})
    return obs;
  }

  dbFindAllTour(query) {
    this.loadToken(); // route is protected
    let headers = new HttpHeaders().set('Authorization', this.authToken); headers.append('Content-Type', 'application/json');
    let obs: any = this.httpClient.post(this.configGlobals.http_string + 'tour_r/dbfindalltour', query, {headers: headers})
    return obs;
  }

  dbModifyTour(query) {
    this.loadToken(); // route is protected
    let headers = new HttpHeaders().set('Authorization', this.authToken); headers.append('Content-Type', 'application/json');
    let obs: any = this.httpClient.post(this.configGlobals.http_string + 'tour_r/dbmodifytour', query, {headers: headers})
    return obs;
  }

  dbInsertTour(query) {
    this.loadToken(); // route is protected
    let headers = new HttpHeaders().set('Authorization', this.authToken); headers.append('Content-Type', 'application/json');
    let obs: any = this.httpClient.post(this.configGlobals.http_string + 'tour_r/dbinserttour', query, {headers: headers})
    return obs;
  }

  dbDuplicateTour(query) {
    this.loadToken(); // route is protected
    let headers = new HttpHeaders().set('Authorization', this.authToken); headers.append('Content-Type', 'application/json');
    let obs: any = this.httpClient.post(this.configGlobals.http_string + 'tour_r/dbduplicatetour', query, {headers: headers})
    return obs;
  }

  dbDeleteTour(query) {
    this.loadToken(); // route is protected
    let headers = new HttpHeaders().set('Authorization', this.authToken); headers.append('Content-Type', 'application/json');
    let obs: any = this.httpClient.post(this.configGlobals.http_string + 'tour_r/dbdeletetour', query, {headers: headers})
    return obs;
  }

}
