import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigGlobals } from '../globals/config';

@Injectable({
  providedIn: 'root'
})
export class ItemsService {

  authToken: any;
  // items_db: any;

  constructor( private httpClient : HttpClient,
               private configGlobals: ConfigGlobals) 
  {}

  /*** Json WebToken laden für Protected Routes ****/
  loadToken() {
    const token = localStorage.getItem('id_token');
    this.authToken = token;
  }

  dbCountTableItems(query) {
    this.loadToken(); // route is protected
    let headers = new HttpHeaders().set('Authorization', this.authToken); headers.append('Content-Type', 'application/json');
    let obs: any = this.httpClient.post(this.configGlobals.http_string + 'items_r/dbcounttableitems', query, {headers: headers});
    return obs;
  } 

  dbFindPageItems(query) {
    this.loadToken(); // route is protected
    let headers = new HttpHeaders().set('Authorization', this.authToken); headers.append('Content-Type', 'application/json');
    let obs: any = this.httpClient.post(this.configGlobals.http_string + 'items_r/dbfindpageitems', query, {headers: headers})
    return obs;
  }

  dbFindAllItems(query) {
    this.loadToken(); // route is protected
    let headers = new HttpHeaders().set('Authorization', this.authToken); headers.append('Content-Type', 'application/json');
    let obs: any = this.httpClient.post(this.configGlobals.http_string + 'items_r/dbfindallitems', query, {headers: headers})
    return obs;
  }

  dbModifyItems(query) {
    this.loadToken(); // route is protected
    let headers = new HttpHeaders().set('Authorization', this.authToken); headers.append('Content-Type', 'application/json');
    let obs: any = this.httpClient.post(this.configGlobals.http_string + 'items_r/dbmodifyitems', query, {headers: headers})
    return obs;
  }

  dbInsertItems(query) {
    this.loadToken(); // route is protected
    let headers = new HttpHeaders().set('Authorization', this.authToken); headers.append('Content-Type', 'application/json');
    let obs: any = this.httpClient.post(this.configGlobals.http_string + 'items_r/dbinsertitems', query, {headers: headers})
    return obs;
  }

  dbDuplicateItems(query) {
    this.loadToken(); // route is protected
    let headers = new HttpHeaders().set('Authorization', this.authToken); headers.append('Content-Type', 'application/json');
    let obs: any = this.httpClient.post(this.configGlobals.http_string + 'items_r/dbduplicateitems', query, {headers: headers})
    return obs;
  }

  dbDeleteItems(query) {
    this.loadToken(); // route is protected
    let headers = new HttpHeaders().set('Authorization', this.authToken); headers.append('Content-Type', 'application/json');
    let obs: any = this.httpClient.post(this.configGlobals.http_string + 'items_r/dbdeleteitems', query, {headers: headers})
    return obs;
  }

}
