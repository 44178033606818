import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { FlashMessagesService } from 'angular2-flash-messages';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {
  username:    string = '';
  password:    string = '';
  password2:   string = '';
  password_message: string = '';
  login_error: boolean = false;
  showAdminModal : boolean = false;

  constructor(private authService: AuthService,
              private router: Router,
              private flashMessage: FlashMessagesService
  ) { }

  ngOnInit() {
    this.checkForAdmin();
  }

  onLoginSubmit() {
    const user = {
          username: this.username,
          password: this.password
    }
   
    this.authService.authenticateUser(user).subscribe(data => {
      // console.log(data.token);
      // console.log(data.user);
      // console.log(data.success);
      if (data.success) {
        this.authService.storeUserData(data.token, data.user);
        // Token + User-Daten kommen zurück
        //this.flashMessage.show('You are now logged in', {cssClass: 'alert-success', timeout: 2000}); 
        //console.log(data.msg); console.log(data.user);
        //console.log('You are now logged in');
        //console.log(data);

        // this.router.navigate(['/profile']);
        this.router.navigate(['/dashboard']);
        
      } else {              // data.msg Message from the Server (users.js)
        this.login_error = true;
        //this.flashMessage.show(data.msg, {cssClass: 'alert-danger', timeout: 2000}); 
        //console.log(data.msg);
        this.router.navigate(['/login']);
      }
    });
  }

  checkForAdmin() {
    // Check if Admin exists in database, if not register one
    const user = {
      username: 'admin',   // wird nicht in der SQL Abfrage benutzt, die ist fest mit <admin> verdrahtet
      password: ''         // nur das Passwort wird übertragen
    }
    //console.log('Check for Admin');
    this.authService.checkforAdmin(user).subscribe(data => {
      if (!data.success) {
        //console.log('Admin Not found', data.msg);
        // Password für Administrator holen
        this.showAdminModal = true;
      }
    });
  };

  registerAdminOk() {
    const user = {
      password: '',
    }
   
    console.log('Register Admin');

    
    if (this.password.length < 3)
       this.password_message = 'Passwort muss mindestens 3 Zeichen lang sein'; 
    else   
        if (this.password != this.password2)
          this.password_message = 'Passwörter sind nicht gleich';
        else {
          this.password_message = 'Passwörter OK, registriere Admin';

          user.password = this.password;
          this.authService.registerAdmin(user).subscribe(data => {
            if (data.success) {
              this.showAdminModal = false;
              this.flashMessage.show('You are now registered and can log in', {cssClass: 'alert-success', timeout: 3000}); 
              this.authService.logout();
              this.router.navigate(['/login']);
            } else { 
              this.showAdminModal = false;
              this.flashMessage.show('Registration failed: '+data.sql, {cssClass: 'alert-danger', timeout: 3000}); 
              this.router.navigate(['/home']);
            }
          })
        };  

  }

  registerAdminCancel() {
     this.password  = '';
     this.password2 = '';
     this.showAdminModal = false;
     this.router.navigate(['/home']);
  }

}
