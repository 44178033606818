import { Component, OnInit, NgZone } from '@angular/core';
import { ItemsdbService } from '../../services/itemsdb.service';
import { FlashMessagesService } from 'angular2-flash-messages';
import { AuthService } from '../../services/auth.service'; // Zugriffs-Rechte
import { Router } from '@angular/router';
//import { State } from "@clr/angular";


@Component({
  selector: 'app-itemsdb',
  templateUrl: './itemsdb.component.html',
  styleUrls: ['./itemsdb.component.css']
})
export class ItemsdbComponent implements OnInit {
  // _id int(11) AI PK 

  group_id: string = '0'; 
  item:     string = '';
  
  s_item:   string = '';    
  
  showInsertModal    = false;
  showModifyModal    = false;
  showDuplicateModal = false;
  showDeleteModal    = false;
 
  selected;  // beinhaltet den selektierten Datensatz im html; normalerweise *false*
  grid_Array         = [];   
  grid_Size          = 10;
  grid_currentPage   = 1;
  grid_totalCount    = 0;    
  grid_searchModus   = false; // false: ab Anfang / true = enthält contains/pattern
  grid_loading       = true;
  
  mobHeight: any;
  mobOffset = 280;
  mobDivide =  39;

  constructor( private itemsdbService: ItemsdbService,
               private flashMessage: FlashMessagesService,
               private authService: AuthService,
               private router: Router,
               private ngZone: NgZone
  ) {

    this.mobHeight = (window.innerHeight);
    this.grid_Size = Math.floor((this.mobHeight - this.mobOffset) / this.mobDivide);
    if (this.grid_Size < 1) {
      this.grid_Size = 1;
    }

    window.onresize = (e) =>
      {   ngZone.run(() => {
              this.mobHeight = window.innerHeight;
              this.grid_Size = Math.floor((this.mobHeight - this.mobOffset) / this.mobDivide);
              if (this.grid_Size < 1) {
                this.grid_Size = 1;
              }
              this.refresh();
          });
      };
  }

  ngOnInit() {
  }

  // *** COUNT & FIND Item_DB **************************************************
  // refresh(state: State) {  .. wenn Tastenstatus / Maus-Event eine Rolle spielt
  refresh() {      
    const query = {
      page    : 0,
      size    : 0,
      qstring : '',
    }
    var q = 'WHERE ';
    var m = '';

    if (this.grid_searchModus)  m = '%';
    else                        m = '';
   
    this.s_item = this.s_item.trim();
    if (this.s_item  != '') {q = q + "item LIKE '"  + m + this.s_item +"%'"; }    
    
    if (q.length < 7) //q = '';  // alle Felder waren leer
       q = "ORDER BY group_id, item ASC"  ;

    query.size    = this.grid_Size;
    query.page    = this.grid_currentPage;
    query.qstring = q;

    this.grid_loading = true;
    
    this.itemsdbService.dbCountTableItems_db(query).subscribe(data => {
      if (data.success) {
        //this.flashMessage.show('Items_DB counted', {cssClass: 'alert-success', timeout: 2000}); 
        this.grid_totalCount = data.count[0].anzahl;
        if (this.grid_totalCount > 0) {
          this.itemsdbService.dbFindPageItems_db(query).subscribe(data => {
            if (data.success) {
              //this.flashMessage.show('Items_DB found', {cssClass: 'alert-success', timeout: 2000}); 
              this.grid_Array = data.items; 
              this.selected = this.grid_Array[0];
              this.grid_loading = false;
              //console.log(this.grid_Array);
            } else {
              this.flashMessage.show('Items_DB not found, check connection', {cssClass: 'alert-danger', timeout: 2000}); 
            }
          });
        } else {
          delete this.grid_Array; 
          this.selected = [];
          this.grid_loading = false;
          // this.flashMessage.show('Items_DB not counted or found, check connection', {cssClass: 'alert-danger', timeout: 2000}); 
        }
      } else {
        delete this.grid_Array; 
        this.selected = [];
        this.grid_loading = false;
        this.flashMessage.show('Items_DB not counted or found, check connection', {cssClass: 'alert-danger', timeout: 2000}); 
      }
    });

  }
  
  // *** Modify EDIT ***************************
  selectedModifyModalOK() {
      const query = { qstring : '' } 

      // Validate Rights
      if (!this.authService.checkOkRights(50)) {
        this.showModifyModal = false;
        this.refresh();  // muss hier sein
        this.flashMessage.show('> Keine Rechte zur Modifikation <', {cssClass: 'alert-danger', timeout: 3000});
        return false;
      }

      let x_group_id = parseInt(this.selected.group_id);
      if (isNaN(x_group_id)) { this.selected.group_id = 0; }

      query.qstring = "group_id="+this.selected.group_id+",item='"+this.selected.item+"' WHERE _id = "+this.selected._id;

      this.showModifyModal = false;

      this.itemsdbService.dbModifyItems_db(query).subscribe(data => {
        if (data.success) {
          //this.flashMessage.show('Item modified', {cssClass: 'alert-success', timeout: 1000}); 
          this.refresh();
        } else {
          this.flashMessage.show('Item not modified, check connection', {cssClass: 'alert-danger', timeout: 3000}); 
        }
      });
  }
  selectedModifyModalCANCEL() {
    this.showModifyModal = false;
    this.refresh();  // muss hier sein
  }

  // *** INSERT NEW **********************
  selectedInsertModalOK() {
    const query = { qstring : '' } 

    // Validate Rights
    if (!this.authService.checkOkRights(50)) {
      this.showInsertModal = false;
      //this.refresh();
      this.flashMessage.show('> Keine Rechte zur Modifikation <', {cssClass: 'alert-danger', timeout: 3000});
      return false;
    }

    let x_group_id = parseInt(this.group_id);
    if (isNaN(x_group_id)) { x_group_id = 1; }

    query.qstring = "group_id="+x_group_id+",item='"+this.item+"'";

    this.showInsertModal = false;

    this.itemsdbService.dbInsertItems_db(query).subscribe(data => {
      if (data.success) {
        //this.flashMessage.show('Item inserted', {cssClass: 'alert-success', timeout: 1000}); 
        this.refresh();
      } else {
        this.flashMessage.show('Item not inserted, check connection', {cssClass: 'alert-danger', timeout: 3000}); 
      }
    });
   
    this.s_item  = '';    
    
    this.refresh();
  }
  selectedInsertModalCANCEL() {
    this.showInsertModal = false;
    //this.refresh();
  }

  // *** DUPLICATE ***************************
  selectedDuplicateModalOK() {
    const query = { qstring : '' } 

    if (!this.authService.checkOkRights(50)) {
      this.showDuplicateModal = false;
      //this.refresh();
      this.flashMessage.show('> Keine Rechte zur Modifikation <', {cssClass: 'alert-danger', timeout: 3000});
      return false;
    }    

    let fields = "group_id, item";
    query.qstring = fields+") SELECT "+fields+" FROM items_db WHERE _id = "+this.selected._id;

    this.showDuplicateModal = false;

    this.itemsdbService.dbDuplicateItems_db(query).subscribe(data => {
      if (data.success) {
        //this.flashMessage.show('Item duplicated', {cssClass: 'alert-success', timeout: 1000}); 
        this.refresh();
      } else {
        this.flashMessage.show('Item not duplicated, check connection', {cssClass: 'alert-danger', timeout: 3000}); 
      }
    });

    // this.selected = false; produziert Fehler
    this.refresh();
  }
  selectedDuplicateModalCANCEL() {
  this.showDuplicateModal = false;
  // this.refresh();
  }

  // *** DELETE ***************************
  selectedDeleteModalOK() {
    const query = { qstring : '' } 

    if (!this.authService.checkOkRights(50)) {
      this.showDeleteModal = false;
      //this.refresh();
      this.flashMessage.show('> Keine Rechte zur Modifikation <', {cssClass: 'alert-danger', timeout: 3000});
      return false;
    }    

    query.qstring= this.selected._id;
    
    this.showDeleteModal = false;

    this.itemsdbService.dbDeleteItems_db(query).subscribe(data => {
      if (data.success) {
        //this.flashMessage.show('Article deleted', {cssClass: 'alert-success', timeout: 1000}); 
        this.refresh();
      } else {
        this.flashMessage.show('Item not deleted, check connection', {cssClass: 'alert-danger', timeout: 3000}); 
      }
    });

    //  this.selected = false;
    //  this.refresh();
  }
  selectedDeleteModalCANCEL() {
  this.showDeleteModal = false;
  // this.refresh();
  }

}
